import { BoxButton } from "@daangn/sprout-components-button";
import { chain } from "@daangn/sprout-react-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import * as React from "react";

import { AlertDialogSecondaryAction } from "./AlertDialogSecondaryAction";
import { useAlertDialogProps } from "./context";
import { Dialog, DialogDescription, DialogHeader, DialogTitle } from "./Dialog";
import type { SeedAlertDialogProps } from "./types";

const AlertDialog = (
  props: SeedAlertDialogProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  const { api } = useAlertDialogProps();
  const { styleProps } = useStyleProps(props);
  const {
    title,
    description,
    primaryActionLabel,
    secondaryActionLabel,
    secondaryActionIntent = "alternative",
    onPrimaryAction = () => {},
    onSecondaryAction = () => {},
  } = props;

  const { close } = api;
  const isSecondaryActionVisible = !!secondaryActionLabel;

  return (
    <Dialog ref={ref} {...styleProps}>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription>{description}</DialogDescription>
      </DialogHeader>
      <div
        data-part="button-container"
        data-action-intent={secondaryActionIntent}
        className={composeClassName("seed-alert-dialog")}
      >
        <BoxButton
          onClick={() => chain(close(), onPrimaryAction())}
          variant="primary"
          size="medium"
        >
          {primaryActionLabel}
        </BoxButton>
        {isSecondaryActionVisible && (
          <AlertDialogSecondaryAction
            onClick={() => chain(close(), onSecondaryAction())}
            intent={secondaryActionIntent}
          >
            {secondaryActionLabel}
          </AlertDialogSecondaryAction>
        )}
      </div>
    </Dialog>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _AlertDialog = React.forwardRef(AlertDialog);
export { _AlertDialog as AlertDialog };
